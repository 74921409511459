<template>
  <nav class="bg-white md:rounded-[10px] md:backdrop-blur-[23px] md:flex md:items-center md:justify-center md:h-auto md:py-[15px] md:-mb-[50px]">
    <div class=" mx-auto px-2 md:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="absolute inset-y-0 right-6 flex items-center md:hidden">
          <button class="inline-flex items-center justify-center p-2 rounded-md text-black bg-[##f2f2f2]" @click="toggleMenu">
            <span class="sr-only">Open main menu</span>
            <svg
              :class="{'block h-6 w-6': !isOpen, 'hidden': isOpen}"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
            <svg
              :class="{'block h-6 w-6': isOpen, 'hidden': !isOpen}"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
          <div class="flex-shrink-0 pl-6 md:hidden py-[5px]">
            <logo-color />
          </div>
          <div class="hidden md:block md:ml-6">
            <div class="flex space-x-4 items-center justify-center flex-wrap 2xl:flex-nowrap">
              <template v-for="item in menuData" :key="item.id">
                <div v-if="item.has_dropdown" class="relative group text-neutral-900 font-normal">
                  <NuxtLink :to="item.link" class="menu-item-has-children px-3 py-3 rounded-md text-[20px] font-normal font-['Montserrat'] capitalize">
                    {{ item.title }}
                  </NuxtLink>
                  <div class="group absolute mt-2 left-0 w-48 bg-[#6f8e92] shadow-lg hidden group-hover:block duration-300 ease-in-out">
                    <template v-for="subItem in item.sub_menu" :key="subItem.id">
                      <NuxtLink :to="subItem.link" class="block px-4 py-2 text-[16px] text-white font-normal hover:bg-[#3f444c] hover:text-white">
                        {{ subItem.title }}
                      </NuxtLink>
                    </template>
                  </div>
                </div>
                <NuxtLink v-else :to="item.link" class="px-3 py-2 rounded-md text-[20px] font-normal font-['Montserrat'] capitalize" @click="closeMenu">
                  {{ item.title }}
                </NuxtLink>
              </template>
              <div class="flex justify-center h-auto">
                <CommonButtonsEstimate text="Free Roofing Estimate" :icon="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="{'block': isOpen, 'hidden': !isOpen}" class="md:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1 absolute right-0 bg-[#6f8e92] w-full z-10">
        <template v-for="item in menuData" :key="item.id">
          <div v-if="item.has_dropdown">
            <NuxtLink class="menu-item-has-children block text-white focus:bg-[#3f444c] hover:bg-[#3f444c] hover:text-white px-3 py-2 rounded-md text-base font-medium" @click="toggleDropdown(item.id)">
              {{ item.title }}
            </NuxtLink>
            <div :class="{'block': isDropdownOpen(item.id), 'hidden': !isDropdownOpen(item.id)}" class="pl-4 space-y-1">
              <template v-for="subItem in item.sub_menu" :key="subItem.id">
                <NuxtLink :to="subItem.link" class="block text-white focus:bg-[#3f444c] px-3 py-2 rounded-md text-base font-medium" @click="closeMenu">
                  {{ subItem.title }}
                </NuxtLink>
              </template>
            </div>
          </div>
          <NuxtLink v-else :to="item.link" class="block text-white focus:bg-[#3f444c] px-3 py-2 rounded-md text-base font-medium" @click="closeMenu">
            {{ item.title }}
          </NuxtLink>
        </template>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue'

const isOpen = ref(false)
const openDropdownId = ref(null)
const menuData = [
  { id: 1, title: 'Home', link: '/', has_dropdown: false },
  { id: 2, title: 'Our Team', link: '/our-team', has_dropdown: false },
  {
    id: 3,
    title: 'Roofing Services',
    link: '#',
    has_dropdown: true,
    sub_menu: [
      { id: 1, title: 'Roof Replacements', link: '/roofing-services/roof-replacements' },
      { id: 2, title: 'Exterior Remodeling', link: '#' }
    ]
  },
  { id: 4, title: 'Financing', link: '/financing', has_dropdown: false },
  {
    id: 5,
    title: 'Roof Types',
    link: '#',
    has_dropdown: true,
    sub_menu: [
      { id: 1, title: 'Asphalt Roofing', link: '/roof-types/asphalt' },
      { id: 2, title: 'Metal Roofing', link: '/roof-types/metal' },
      { id: 3, title: 'Cedar Roofing', link: '/roof-types/cedar' }
    ]
  },
  { id: 6, title: 'Contact Us', link: '/contact-us', has_dropdown: false }
]

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

const closeMenu = () => {
  isOpen.value = false
}

const toggleDropdown = (id) => {
  openDropdownId.value = openDropdownId.value === id ? null : id
}

const isDropdownOpen = (id) => {
  return openDropdownId.value === id
}
</script>

<style scoped>
/* Add custom styles here if needed */
</style>
